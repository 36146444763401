import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Moderator Benefits`}</h1>
    <ul>
      <li parentName="ul">{`Having a moderator is an efficient solution, despite the risks of assuming a `}<a parentName="li" {...{
          "href": "/benevolent-moderator",
          "title": "benevolent moderator"
        }}>{`benevolent moderator`}</a>{` and other `}<a parentName="li" {...{
          "href": "/risks-to-having-a-moderator",
          "title": "risks to having a moderator"
        }}>{`risks to having a moderator`}</a>{``}</li>
      <li parentName="ul">{`A large input to the quality of a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` is the density of the `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{`, nonsense or malformed comments reduce usable density`}</li>
      <li parentName="ul">{`Offensive comments displayed on high profile properties disqualify the method:`}
        <ul parentName="li">
          <li parentName="ul">{`Major institutions cannot use the method if it will prominently feature hate speech on their website, sometimes, randomly`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      